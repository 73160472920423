import { factory } from '../../../utils/factory.js';
import { DimensionError } from '../../../error/DimensionError.js';
var name = 'matAlgo08xS0Sid';
var dependencies = ['typed', 'equalScalar'];
export var createMatAlgo08xS0Sid = /* #__PURE__ */factory(name, dependencies, _ref => {
  var {
    typed,
    equalScalar
  } = _ref;
  /**
   * Iterates over SparseMatrix A and SparseMatrix B nonzero items and invokes the callback function f(Aij, Bij).
   * Callback function invoked MAX(NNZA, NNZB) times
   *
   *
   *          ┌  f(Aij, Bij)  ; A(i,j) !== 0 && B(i,j) !== 0
   * C(i,j) = ┤  A(i,j)       ; A(i,j) !== 0 && B(i,j) === 0
   *          └  0            ; otherwise
   *
   *
   * @param {Matrix}   a                 The SparseMatrix instance (A)
   * @param {Matrix}   b                 The SparseMatrix instance (B)
   * @param {Function} callback          The f(Aij,Bij) operation to invoke
   *
   * @return {Matrix}                    SparseMatrix (C)
   *
   * see https://github.com/josdejong/mathjs/pull/346#issuecomment-97620294
   */
  return function matAlgo08xS0Sid(a, b, callback) {
    // sparse matrix arrays
    var avalues = a._values;
    var aindex = a._index;
    var aptr = a._ptr;
    var asize = a._size;
    var adt = a._datatype || a._data === undefined ? a._datatype : a.getDataType();
    // sparse matrix arrays
    var bvalues = b._values;
    var bindex = b._index;
    var bptr = b._ptr;
    var bsize = b._size;
    var bdt = b._datatype || b._data === undefined ? b._datatype : b.getDataType();

    // validate dimensions
    if (asize.length !== bsize.length) {
      throw new DimensionError(asize.length, bsize.length);
    }

    // check rows & columns
    if (asize[0] !== bsize[0] || asize[1] !== bsize[1]) {
      throw new RangeError('Dimension mismatch. Matrix A (' + asize + ') must match Matrix B (' + bsize + ')');
    }

    // sparse matrix cannot be a Pattern matrix
    if (!avalues || !bvalues) {
      throw new Error('Cannot perform operation on Pattern Sparse Matrices');
    }

    // rows & columns
    var rows = asize[0];
    var columns = asize[1];

    // datatype
    var dt;
    // equal signature to use
    var eq = equalScalar;
    // zero value
    var zero = 0;
    // callback signature to use
    var cf = callback;

    // process data types
    if (typeof adt === 'string' && adt === bdt && adt !== 'mixed') {
      // datatype
      dt = adt;
      // find signature that matches (dt, dt)
      eq = typed.find(equalScalar, [dt, dt]);
      // convert 0 to the same datatype
      zero = typed.convert(0, dt);
      // callback
      cf = typed.find(callback, [dt, dt]);
    }

    // result arrays
    var cvalues = [];
    var cindex = [];
    var cptr = [];

    // workspace
    var x = [];
    // marks indicating we have a value in x for a given column
    var w = [];

    // vars
    var k, k0, k1, i;

    // loop columns
    for (var j = 0; j < columns; j++) {
      // update cptr
      cptr[j] = cindex.length;
      // columns mark
      var mark = j + 1;
      // loop values in a
      for (k0 = aptr[j], k1 = aptr[j + 1], k = k0; k < k1; k++) {
        // row
        i = aindex[k];
        // mark workspace
        w[i] = mark;
        // set value
        x[i] = avalues[k];
        // add index
        cindex.push(i);
      }
      // loop values in b
      for (k0 = bptr[j], k1 = bptr[j + 1], k = k0; k < k1; k++) {
        // row
        i = bindex[k];
        // check value exists in workspace
        if (w[i] === mark) {
          // evaluate callback
          x[i] = cf(x[i], bvalues[k]);
        }
      }
      // initialize first index in j
      k = cptr[j];
      // loop index in j
      while (k < cindex.length) {
        // row
        i = cindex[k];
        // value @ i
        var v = x[i];
        // check for zero value
        if (!eq(v, zero)) {
          // push value
          cvalues.push(v);
          // increment pointer
          k++;
        } else {
          // remove value @ i, do not increment pointer
          cindex.splice(k, 1);
        }
      }
    }
    // update cptr
    cptr[columns] = cindex.length;

    // return sparse matrix
    return a.createSparseMatrix({
      values: cvalues,
      index: cindex,
      ptr: cptr,
      size: [rows, columns],
      datatype: adt === a._datatype && bdt === b._datatype ? dt : undefined
    });
  };
});